<template>
    <section class="content-wrapper">
        <pageLoader v-if="loadershow" :loadingText="'Traitement des données en cours'"/>
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="auth-form-light text-left p-5">
                            <div class="brand-logo text-center">
                            <img src="../assets/images/logo.png">
                            </div>
                            <h4 class="text-center mt-4">Un code a été envoyé à votre adresse mail</h4>
                            <h6 class="font-weight-light text-center">Veuillez entrer le code envoyé</h6>
                            <form class="pt-3" @submit.prevent="handleSubmit">

                            <!-- nouveau mot de passe -->
                            <b-form-group id="password1" label="Nouveau mot de passe" label-for="password1">
                                <b-form-input
                                    id="password1"
                                    name="password1"
                                    type="password"
                                    v-model="password1"
                                    :state="$v.password1.$dirty ? !$v.password1.$error : null"
                                    aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                    Ce champ ne doit pas être vide
                                </b-form-invalid-feedback>
                            </b-form-group>

                              <!-- Confirmation du mot de passe -->
                            <b-form-group id="password-2" label="Confirmer le mot de passe" label-for="password2">
                                <b-form-input
                                  id="password2"
                                  name="password2"
                                  type="password"
                                  v-model="password2"
                                  :state="$v.password2.$dirty ? !$v.password2.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Les mots de passes ne sont pas les mêmes
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <div class="mt-3">
                                <button class="btn btn-block btn-primary  btn-lg font-weight-medium auth-form-btn">Changer mon mot de passe</button>
                            </div>
                            <div class="my-2 d-flex justify-content-between align-items-center">
                            </div>

                            <div>
                                <p class="text-center">Vous n'avez pas reçu l'email? N'oubliez pas de regarder  dans vos spams.</p>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
const axios = require('axios').default
import { validationMixin } from 'vuelidate'
import { required, sameAs} from 'vuelidate/lib/validators'
import pageLoader from '../components/pageLoader.vue'
export default {
    name: 'new-password',
    mixins: [validationMixin],
    components:{
        pageLoader
    },
    data() {
        return {
            token: '',
            pwd: '',
            password1:'',
            password2:'',
            ulid:'',
            loadershow:false
        }
    },
    validations: {
        password1: {
            required
        },
        password2: {
            required,
            sameAs:sameAs('password1')
        }
    },
    methods: {
        handleSubmit(){
            this.$v.$touch()
            if (this.$v.$anyError) {
                alert('any error')
                return
            }
            this.putPassword()
        },
        putPassword(){
            let headers = {}
            this.$route.params.token?headers = {"X-AUTH-TOKEN": this.token}:headers = {}
            console.log({headers});
            axios.put(`myinfos`,{
                password:this.password1
            },
            {
                headers:{
                    "X-AUTH-TOKEN": this.token
                }
            }
            )
            .then(res => {
                res
                alert('Votre mot de passe a été modifié avec succès')
                this.$router.push({
                    name:"login"
                })
            })
            .catch(err => {
                console.log(err)
            })
        }
    },
    mounted() {
        if(this.$route.params.token) {
            this.token = this.$route.params.token
        }
    },
    beforeCreate() {
        // supprimer X-Auth-token sur toutes les requêtes de la page
      delete axios.defaults.headers.common['X-AUTH-TOKEN'] 
    }
}
</script>